<template>
  <e-sidebar
    id="sidebar-form-estimate-product"
    :title="isEdit ? $t('Editar produto') : $t('Adicionar produto')"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    width="600px"
    @save="onSaveItem"
    @hidden="hide"
  >
    <template #content>
      <FormulateForm
        ref="itemSidebarForm"
        name="itemSidebarForm"
      >
        <b-row>
          <b-col>
            <e-search-sku
              id="sale_product_sidebar-sku_id"
              v-model="itemForm.skuProduct"
              input-id="sale_product_sidebar-sku_product"
              value-is-object
              :store-id="storeId"
              :price-table-id="priceTableId"
              :disabled="isEdit"
              autofocus
              @input="onSelectProduct"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <FormulateInput
              id="sale_product_sidebar-quantity"
              v-model.number="itemForm.quantity"
              type="text-number"
              class="required"
              :label="$t('Quantidade')"
              validation="required|min:1"
              @input="updateInfos"
            />
          </b-col>
          <template v-if="!isKit">
            <b-col md="4">
              <e-slot-authenticated
                v-model="itemForm.unitDiscountAuth"
                :delegable-permission="unitDiscountPermission"
                :store-id="storeId"
                :is-valid-store="!!storeId"
              >
                <template #content="slotProps">
                  <FormulateInput
                    id="discount-type"
                    v-model="itemForm.discountType"
                    type="radio"
                    :options="{
                      [discountTypesEnum.VALUE]: 'R$',
                      [discountTypesEnum.PERCENTAGE]: '%',
                    }"
                    :label="$t('Desconto em')"
                    :element-class="['d-flex', 'mt-1']"
                    :disabled="slotProps.isReadOnly"
                  />
                </template>
              </e-slot-authenticated>
            </b-col>

            <b-col md="4">
              <e-slot-authenticated
                v-model="itemForm.unitDiscountAuth"
                :delegable-permission="unitDiscountPermission"
                :store-id="storeId"
                :is-valid-store="!!storeId"
              >
                <template #content="slotProps">
                  <FormulateInput
                    v-if="slotProps.isReadOnly"
                    id="sale_product_sidebar-discount"
                    v-model="itemForm.unitDiscount"
                    name="discount_percentage"
                    type="label"
                    :label="`${$t('Desconto')} (${getDiscountSymbol})`"
                    :filter="isDiscountPercent ? 'percentage' : 'currency'"
                    :instruction="$t('Desconto do pedido')"
                  />
                  <FormulateInput
                    v-else
                    id="sale_product_sidebar-discount"
                    v-model="itemForm.unitDiscount"
                    name="discount_percentage"
                    :type="isDiscountPercent ? 'text-percetage' : 'text-number'"
                    :label="`${$t('Desconto Un.')} (${getDiscountSymbol})`"
                    :currency="getDiscountSymbol"
                    :currency-symbol-position="isDiscountPercent ? 'suffix' : 'prefix'"
                    :precision="2"
                    :instruction="$t('Desconto de 1 unidade')"
                    validation="required"
                    class="required"
                    @input="updateInfos"
                  />
                </template>
              </e-slot-authenticated>
            </b-col>
          </template>
        </b-row>

        <b-row>
          <b-col>
            <div>
              <p class="h6 mb-0">
                {{ $t('Preço unitário') }}
              </p>
              <p class="h4 text-bold">
                {{ infoValues.productPrice | currency }}
                <span class="h5"> x{{ itemForm.quantity }} </span>
              </p>
            </div>
          </b-col>
          <b-col v-if="!isKit">
            <div v-if="hasPriceTableDiscount">
              <p class="h6 mb-0">
                {{ $t('Desconto da tabela de preço') }}
              </p>
              <p class="h4 text-bold">
                {{ infoValues.priceTableDiscountTotal | currency }}
              </p>
            </div>

            <div>
              <p class="h6 mb-0">
                {{ $t('Desconto total') }}
              </p>
              <p class="h4 text-bold">
                {{ infoValues.totalDiscount | currency }}
              </p>
            </div>
          </b-col>
          <b-col>
            <div>
              <p class="h6 mb-0">
                {{ $t('Total') }}
              </p>
              <p class="h4 text-bold">
                {{ infoValues.total | currency }}
              </p>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <div>
              <p class="h6 mb-0">
                {{ $t('Quant. em estoque') }}
              </p>
              <p
                class="h4 text-bold"
                :class="{ 'text-danger': infoValues.stock < 0 }"
              >
                {{ infoValues.stock === 0 ? 0 : infoValues.stock || '-' }}
              </p>
            </div>
          </b-col>
          <b-col v-if="infoValues.promotionId">
            <div>
              <p class="h6 mb-0">
                {{ $t('Quant. em promoção') }}
              </p>
              <p class="h4 text-bold">
                <span v-if="infoValues.promotionQuantityUnlimited">
                  {{ $t('Ilimitado') }}
                </span>
                <span v-else>
                  {{ getPromotionQuantityAvailable }}
                </span>
              </p>
            </div>
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import _ from 'lodash'
import { BRow, BCol } from 'bootstrap-vue'
import ESidebar from '@/views/components/ESidebar.vue'
import { authorizationRules, discountTypes, formulateHelper, payBoxUtils } from '@/mixins'
import { calculatePrice, getInitialItemForm } from '@/store/pages/sale/quotation/quotation-maintain'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'
import ESlotAuthenticated from '@/views/components/ESlotAuthenticated.vue'
import delegablePermissions from '@/utils/delegable-permissions'

const getInitialInfoValues = () => ({
  productPrice: 0,
  subtotal: 0,
  total: 0,
  totalDiscount: 0,
  priceTableDiscountTotal: 0,
  stock: null,
  promotionId: null,
  promotionQuantityAvailable: 0,
  promotionQuantityUnlimited: false,
})

export default {
  components: { BRow, BCol, ESidebar, ESearchSku, ESlotAuthenticated },

  mixins: [discountTypes, formulateHelper, authorizationRules],

  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
    priceTableId: {
      type: [String, Number],
      default: '',
    },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
      itemForm: getInitialItemForm(),
      infoValues: getInitialInfoValues(),
      productEnriched: getInitialItemForm(),
      delegateUserData: null,
    }
  },

  computed: {
    isDiscountPercent() {
      return this.itemForm.discountType === this.discountTypesEnum.PERCENTAGE
    },

    getDiscountSymbol() {
      return this.isDiscountPercent ? this.$t('%') : this.$t('R$')
    },

    getPromotionQuantityAvailable() {
      return this.infoValues.promotionQuantityAvailable <= 0
        ? this.$t('Esgotado')
        : this.infoValues.promotionQuantityAvailable || '-'
    },

    isEdit() {
      return !!this.itemForm.id || !!this.itemForm.localId
    },

    isKit() {
      return this.productEnriched?.kitItems?.length > 0
    },

    unitDiscountPermission() {
      return delegablePermissions.ERP_ORDER_ITEM_DISCOUNT
    },

    hasPriceTableDiscount() {
      return (
        !this.productEnriched?.skuProduct?.promotionId &&
        (this.productEnriched?.discountPriceTable > 0 ||
          this.productEnriched?.skuProduct?.priceTable?.discount > 0)
      )
    },
  },

  methods: {
    async show(item) {
      this.cleanSidebar()
      this.showSidebar = true

      if (item) {
        try {
          this.fetching = true
          await new Promise(resolve => setTimeout(() => resolve(), 200))
          this.itemForm = { ...item }
          this.itemForm.discountType = this.itemForm.discountType || this.discountTypesEnum.VALUE

          this.productEnriched = { ...item }
          await this.fetchSkuStockInfo()
          this.updateInfos()
        } finally {
          this.fetching = false
        }
      }
    },

    hide() {
      this.cleanSidebar()
      this.showSidebar = false
    },

    cleanSidebar() {
      this.itemForm = getInitialItemForm()
      this.productEnriched = getInitialItemForm()
      this.infoValues = getInitialInfoValues()
    },

    async onSaveItem() {
      try {
        this.saving = true
        this.$refs.itemSidebarForm.showErrors()
        if (this.$refs.itemSidebarForm.hasErrors) {
          this.showInvalidDataMessage()
          return
        }

        if (this.isEdit) {
          this.$emit('update', this.itemForm)
        } else {
          this.$emit('add', { ...this.itemForm, ...this.productEnriched })
        }

        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    async onSelectProduct(productSearched) {
      if (!productSearched) return
      try {
        this.saving = true
        if (!productSearched) return
        const { ean } = productSearched
        const formData = this.itemForm

        const { data } = await this.$http.get(
          `/api/sales/pay-box-sku/store/${this.storeId}/ean/${ean}`,
          { params: { priceTableId: this.priceTableId } }
        )

        if (data?.stock <= 0) {
          await this.confirm({
            title: this.$t('Produto sem estoque'),
            text: this.$t(`Este produto não pode ser adicionado pois está sem estoque.`),
            cancelButtonText: this.$t('Continuar'),
            showCancelButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
            focusCancel: true,
          })
          this.cleanSidebar()
          this.focusInput('#sale_product_sidebar-sku_product')
          return
        }

        this.focusInput('#sale_product_sidebar-quantity')

        const kitItems = data.kitItems?.map(item => {
          const itemQuantity = item.quantity * formData.quantity
          const { priceInfo, netValue } = payBoxUtils.calculateItemPrice({
            price: item.price,
            discountType: item.discountType,
            quantity: itemQuantity,
            unitDiscount: item.unitDiscount,
          })

          const kitLendingProduct = this.prepareLendingProduct(
            item?.lendingProductAssociated,
            itemQuantity
          )

          return {
            ...getInitialItemForm(),
            skuProduct: { ...item },
            lendingProducts: [...kitLendingProduct],
            priceTable: item.priceTable,
            discountType: item.discountType,
            unitDiscount: item.unitDiscount,
            price: item.price,
            quantity: itemQuantity,
            subtotal: priceInfo.grossValue,
            total: netValue,
            totalDiscount: priceInfo.discountTotal,
          }
        })

        const lending = data.lendingProductAssociated
        let lendingProducts = []
        if (lending) {
          const lendingQuantity = lending.quantity * formData.quantity
          const { priceInfo: lendingPriceInfo, netValue: lendingNetValue } =
            payBoxUtils.calculateItemPrice({
              price: lending.price,
              discountType: lending.discountType,
              quantity: lendingQuantity,
              unitDiscount: lending.unitDiscount,
            })

          lendingProducts = [
            {
              ...getInitialItemForm(),
              priceTable: lending.priceTable,
              discountType: lending.discountType,
              unitDiscount: lending.unitDiscount,
              price: lending.price,
              skuProduct: { ...lending },
              quantity: lendingQuantity,
              subtotal: lendingPriceInfo.grossValue,
              total: lendingNetValue,
              totalDiscount: lendingPriceInfo.discountTotal,
            },
          ]
        }

        const product = {
          priceTable: data.priceTable,
          discountPriceTable: data.priceTable?.discount || 0,
          skuProduct: { ...data, label: data.name },
          lendingProducts,
          kitItems: kitItems ?? [],
          price: data.price,
          promotionId: data.promotionId,
        }
        this.productEnriched = product
        this.updateInfos()
      } catch (error) {
        this.itemForm.skuProduct = null
        this.productEnriched = getInitialItemForm()
        this.infoValues = getInitialInfoValues()
        this.showError({ error })
        this.focusInput('#sale_product_sidebar-sku_product', false)
      } finally {
        this.saving = false
      }
    },

    prepareLendingProduct(lendingProduct, itemAssociatedQuantity) {
      let lendingProducts = []
      if (lendingProduct) {
        const lendingQuantity = lendingProduct.quantity * itemAssociatedQuantity
        const { priceInfo: lendingPriceInfo, netValue: lendingNetValue } =
          payBoxUtils.calculateItemPrice({
            price: lendingProduct.price,
            discountType: lendingProduct.discountType,
            quantity: lendingQuantity,
            unitDiscount: lendingProduct.unitDiscount || 0,
          })

        lendingProducts = [
          {
            ...getInitialItemForm(),
            skuProduct: { ...lendingProduct },
            priceTable: lendingProduct.priceTable,
            discountType: lendingProduct.discountType,
            unitDiscount: lendingProduct.unitDiscount,
            price: lendingProduct.price,
            quantity: lendingQuantity,
            subtotal: lendingPriceInfo.grossValue,
            total: lendingNetValue,
            totalDiscount: lendingPriceInfo.discountTotal,
          },
        ]
      }

      return lendingProducts
    },

    // eslint-disable-next-line func-names
    updateInfos: _.debounce(function () {
      const { subtotal, total, totalDiscount, priceInfo } = calculatePrice({
        ...this.productEnriched,
        ...this.itemForm,
        skuProduct: this.productEnriched.skuProduct,
      })
      const lendingProducts = this.productEnriched?.lendingProducts

      const { stock, promotionId, promotionQuantityAvailable, promotionQuantityUnlimited } =
        this.productEnriched?.skuProduct || {}

      const promotionAvailable = this.itemForm.id
        ? (promotionQuantityAvailable ?? 0) + (this.itemForm.maxQuantity ?? 0)
        : promotionQuantityAvailable ?? 0

      this.infoValues = {
        productPrice: this.productEnriched.skuProduct?.price || 0,
        subtotal,
        total,
        totalDiscount,
        priceTableDiscountTotal: priceInfo.priceTableDiscountTotal,
        stock: stock ?? null,
        promotionId: promotionId ?? null,
        promotionQuantityAvailable: promotionAvailable,
        promotionQuantityUnlimited: promotionQuantityUnlimited ?? false,
      }

      // atualização da quantidade do produto comodato associado.
      if (lendingProducts && lendingProducts.length > 0) {
        this.productEnriched.lendingProducts = lendingProducts.map(lending => ({
          ...lending,
          quantity: (lending.skuProduct?.quantity || 1) * this.itemForm.quantity,
        }))
      }
    }, 400),

    async fetchSkuStockInfo() {
      const ean = this.productEnriched?.skuProduct?.ean
      if (!_.isNil(this.productEnriched?.skuProduct?.stock) || !ean) {
        return
      }
      const { data } = await this.$http.get(
        `/api/sales/pay-box-sku/store/${this.storeId}/ean/${ean}`,
        { params: { priceTableId: this.priceTableId } }
      )

      this.productEnriched.skuProduct.stock = data.stock

      if (this.itemForm.id) {
        const isToRefreshPromotionData =
          this.itemForm.promotionId &&
          data.promotionId &&
          this.itemForm.promotionId === data.promotionId
        if (isToRefreshPromotionData) {
          const promotionData = {
            priceFrom: data.priceFrom,
            promotionQuantityAvailable: data.promotionQuantityAvailable,
            promotionQuantityUnlimited: data.promotionQuantityUnlimited,
          }

          this.itemForm.skuProduct = {
            ...this.itemForm.skuProduct,
            ...promotionData,
          }

          this.productEnriched.skuProduct = {
            ...this.productEnriched.skuProduct,
            ...promotionData,
          }

          this.updateInfos()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text-bold {
  font-weight: 800;
}
</style>
